@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.bouton.bouton-grand {
  padding: 10px 20px;
  text-transform: uppercase;
}