@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.competence-info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: min-content;
  aspect-ratio: 1;
  z-index: 1;
}
@media (max-width: 767px) {
  .competence-info {
    width: calc(50% - 20px);
  }
}
.competence-info::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 360px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  transition: 0.4s ease-in-out;
}
.competence-info .nom, .competence-info .valeur {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
}
.competence-info .valeur {
  opacity: 1;
  font-size: 26px;
}
@media (max-width: 767px) {
  .competence-info .valeur {
    font-size: 16px;
  }
}
.competence-info .nom {
  font-size: 11px;
  opacity: 0;
}
@media (max-width: 479px) {
  .competence-info .nom {
    font-size: 10px;
  }
}
.competence-info:hover .valeur {
  opacity: 0;
}
.competence-info:hover .nom {
  opacity: 1;
}
.competence-info:hover::before {
  background-color: rgba(0, 0, 0, 0.4);
}
.competence-info .icone-svg {
  position: relative;
  height: 80px;
  width: 80px;
  aspect-ratio: 1;
  padding: 10px;
  border: solid 2px #fff;
  border-radius: 360px;
  background-color: #bf754b;
  z-index: -2;
}
@media (max-width: 767px) {
  .competence-info .icone-svg {
    width: 100%;
    height: 100%;
  }
}
.competence-info .icone-svg path {
  fill: #fff;
}