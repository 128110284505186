@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.champs-edition-personnage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  max-width: 1000px;
  width: 100%;
  padding: 5% 10%;
}
.champs-edition-personnage form {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.champs-edition-personnage form .points-disponibles {
  color: #fff;
  text-align: center;
  font-weight: bold;
}
.champs-edition-personnage form input {
  font-size: 32px;
  color: #fff;
  text-align: center;
}
@media (max-width: 767px) {
  .champs-edition-personnage form input {
    font-size: 24px;
  }
}
.champs-edition-personnage form input::placeholder, .champs-edition-personnage form input:focus, .champs-edition-personnage form input:focus::placeholder {
  color: #fff;
}
.champs-edition-personnage form input:focus {
  border-color: #fff;
}
.champs-edition-personnage form .competences {
  display: flex;
  gap: 40px;
  flex-wrap: nowrap;
}
@media (max-width: 767px) {
  .champs-edition-personnage form .competences {
    flex-wrap: wrap;
    gap: 30px;
    max-width: 400px;
  }
}
@media (max-width: 479px) {
  .champs-edition-personnage form .competences {
    gap: 20px;
  }
}
.champs-edition-personnage form .bouton-envoyer {
  display: block;
  padding: 10px 30px;
  margin: 0 auto;
}

.popupReussi {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 0px;
  width: 200px;
  transform: translateX(-50%);
  background-color: #41cf0d;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

.popupRate {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 0px;
  width: 200px;
  transform: translateX(-50%);
  background-color: #f01212;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}

.show {
  display: block;
}