@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: 10;
}
.popup .popup-conteneur {
  display: flex;
  align-items: center;
  max-width: 900px;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
}
@media (max-width: 767px) {
  .popup .popup-conteneur {
    width: 100%;
    max-width: 500px;
  }
}
.popup .popup-conteneur img {
  height: 100%;
  width: 40%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
@media (max-width: 767px) {
  .popup .popup-conteneur img {
    display: none;
  }
}
.popup .popup-conteneur .conteneur-interne {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  padding: 60px 20px;
  gap: 20px;
}
@media (max-width: 479px) {
  .popup .popup-conteneur .conteneur-interne {
    width: 100%;
    padding: 50px 10px;
  }
}
.popup .popup-conteneur .conteneur-interne .popup-titre {
  text-align: center;
}
.popup .popup-conteneur .conteneur-interne .popup-contenu {
  flex-grow: 1;
  width: 100%;
}
.popup .popup-conteneur .conteneur-interne .popup-contenu .lien-popup {
  margin-top: 20px;
}
.popup .popup-conteneur .conteneur-interne .fermer {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 50px;
  transition: 0.4s ease-in-out;
}
.popup .popup-conteneur .conteneur-interne .fermer:hover {
  color: #bf754b;
}
.popup .popup-conteneur .conteneur-interne .lien-popup {
  text-align: center;
  text-decoration: underline;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  color: #000;
}
.popup .popup-conteneur .conteneur-interne .lien-popup:hover {
  color: #bf754b;
}