@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.panneau-gauche {
  color: #F5F5F5;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 10%;
  background-color: #212121;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.panneau-gauche .image-personnage {
  width: 80px;
  height: auto;
  margin-top: 20px;
}
.panneau-gauche .statistique {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.panneau-gauche .statistique-label {
  font-weight: bold;
}
.panneau-gauche .statistique-valeur {
  margin-top: 5px;
}
.panneau-gauche .inventaire {
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.panneau-gauche .inventaire-label {
  font-weight: bold;
}
.panneau-gauche .inventaire .liste-inventaire {
  list-style-type: none;
  padding: 0;
}
.panneau-gauche .inventaire .liste-inventaire li {
  margin-top: 5px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  padding: 0.2em 0.5em 0.2em 0.5em;
  border-radius: 5px;
}
.panneau-gauche .inventaire .liste-inventaire li:hover {
  background-color: grey;
}