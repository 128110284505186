@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.popup.popup-inventaire .popup-conteneur {
  max-width: 1100px;
  background-color: #f3d9af;
  border: 10px solid #454440;
}
@media (max-width: 1405px) {
  .popup.popup-inventaire .popup-conteneur {
    max-width: 800px;
  }
}
@media (max-width: 980px) {
  .popup.popup-inventaire .popup-conteneur {
    max-width: 500px;
  }
}
.popup.popup-inventaire .popup-conteneur .conteneur-interne {
  width: 100%;
}
.popup.popup-inventaire .popup-conteneur .conteneur-interne .liste-inventaire {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  height: 350px;
  padding: 0 30px;
  overflow-y: auto;
  scrollbar-color: #bf754b #fff;
}
@media (max-width: 767px) {
  .popup.popup-inventaire .popup-conteneur .conteneur-interne .liste-inventaire {
    padding: 0;
  }
}
@media (max-width: 479px) {
  .popup.popup-inventaire .popup-conteneur .conteneur-interne .liste-inventaire {
    gap: 10px;
  }
}
.popup.popup-inventaire .popup-conteneur .conteneur-interne .liste-inventaire .slot {
  position: relative;
  padding: 10px;
  aspect-ratio: 1;
  width: calc(16.6666666667% - 16.6666666667px);
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
.popup.popup-inventaire .popup-conteneur .conteneur-interne .liste-inventaire .slot.slot-vide {
  cursor: initial;
}
@media (max-width: 980px) {
  .popup.popup-inventaire .popup-conteneur .conteneur-interne .liste-inventaire .slot {
    width: calc(33.3333333333% - 13.3333333333px);
  }
}
@media (max-width: 479px) {
  .popup.popup-inventaire .popup-conteneur .conteneur-interne .liste-inventaire .slot {
    width: calc(33.3333333333% - 6.6666666667px);
    border-radius: 15px;
  }
}
.popup.popup-inventaire .popup-conteneur .conteneur-interne .liste-inventaire .slot:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.popup.popup-inventaire .popup-conteneur .conteneur-interne .liste-inventaire .slot:hover .utiliser-item {
  opacity: 1;
}
.popup.popup-inventaire .popup-conteneur .conteneur-interne .liste-inventaire .slot img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.popup.popup-inventaire .popup-conteneur .conteneur-interne .liste-inventaire .slot .utiliser-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0;
  transition: 0.4s ease-in-out;
  font-size: 13px;
}