@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.sectionChamps {
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../medias/images/personnage-adosse-voiture.jpeg") fixed center center/cover no-repeat, #000;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100dvw;
  background: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boutonValiderCreationPersonnage {
  margin-top: 50px;
  appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  isolation: isolate;
}

.boutonValiderCreationPersonnage {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  width: 240px;
  border-radius: 9999rem;
  background: #121212;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  isolation: isolate;
  overflow: hidden;
}
.boutonValiderCreationPersonnage > span.text {
  color: #F5F5F5;
  width: 100%;
  text-align: left;
  padding-block: 12px;
  padding-left: 24px;
  z-index: 2;
  transition: all 200ms ease;
}
.boutonValiderCreationPersonnage > div.overlay {
  color: #ededed;
  width: 48px;
  height: calc(100% - 2px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
  border-radius: inherit;
  background: #242424;
  position: absolute;
  right: 1px;
  z-index: 1;
  transition: all 400ms ease;
}
.boutonValiderCreationPersonnage:is(:hover, :focus) > span.text {
  color: #ededed;
}
.boutonValiderCreationPersonnage:is(:hover, :focus) > div.overlay {
  width: calc(100% - 2px);
  transition: width 200ms ease;
}