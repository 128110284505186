@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

h1 {
  padding: 15px;
  font-size: 40px;
  text-align: center;
}
@media (max-width: 767px) {
  h1 {
    font-size: 28px;
  }
}
@media (max-width: 479px) {
  h1 {
    font-size: 25px;
  }
}
h1 span {
  display: block;
  margin-top: 10px;
  font-size: 28px;
  font-family: inherit;
  font-weight: inherit;
}
@media (max-width: 767px) {
  h1 span {
    font-size: 24px;
  }
}
@media (max-width: 479px) {
  h1 span {
    font-size: 22px;
  }
}