.popupInfo {
  display: none !important;
  position: fixed;
  width: 200px;
  background-color: #41cf0d;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  top: 2em;
  left: 2em;
  z-index: 20;
}

.popupInventaire {
  top: unset;
  bottom: 40px;
  left: 180px;
}

.popupHabilete {
  top: 110px;
  left: 30px;
}

.popupEndurance {
  top: 110px;
  left: 30px;
}

.popupChance {
  top: 110px;
  left: 30px;
}

.popupPerception {
  top: 110px;
  left: 30px;
}

.show {
  display: block !important;
}