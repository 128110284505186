@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.bouton.bouton-recommencer {
  outline: solid;
  border: none;
  border-color: #fff;
  border-radius: 14px;
  border-width: 0 !important;
  text-align: center;
  margin-top: 1em;
  width: 80%;
}
.bouton.bouton-recommencer a, .bouton.bouton-recommencer span {
  display: block;
  padding: 15px 35px;
  color: #000;
  border: none !important;
}
@media (max-width: 767px) {
  .bouton.bouton-recommencer a, .bouton.bouton-recommencer span {
    padding: 10px;
  }
}
.bouton.bouton-recommencer span {
  cursor: pointer;
}