@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

html, body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

#root > * {
  min-height: 100vh;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

h1, h2, h3 {
  font-family: "RussoOne";
  font-weight: 200;
  letter-spacing: 1.2px;
}

p, a, li, input, span, button {
  font-family: "Montserrat";
  font-weight: 500;
  letter-spacing: 0.7px;
  margin: 0;
  font-size: 17px;
}
@media (max-width: 767px) {
  p, a, li, input, span, button {
    font-size: 14px;
  }
}
@media (max-width: 479px) {
  p, a, li, input, span, button {
    font-size: 12px;
  }
}
p.erreur, a.erreur, li.erreur, input.erreur, span.erreur, button.erreur {
  color: red;
}

input {
  font-weight: 700;
  outline: none !important;
  border: none;
}

a {
  text-decoration: none;
}

ul li {
  list-style: none;
}