@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

input {
  width: 100%;
  background-color: transparent;
  border-bottom: 2px solid gray;
  padding: 10px 5px;
  transition: 0.4s ease-in-out;
}
input::placeholder {
  transition: 0.4s ease-in-out;
}
input:focus {
  color: #bf754b;
  border-bottom-color: #bf754b;
}
input:focus::placeholder {
  color: #bf754b;
}