@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.competence {
  display: flex;
  flex-direction: column;
  width: 25%;
}
@media (max-width: 767px) {
  .competence {
    width: calc(50% - 15px);
  }
}
@media (max-width: 479px) {
  .competence {
    width: calc(50% - 10px);
  }
}
.competence .nom {
  text-align: center;
  color: #fff;
  font-weight: 600;
}
.competence .icone-svg {
  flex-grow: 1;
  display: block;
  width: 100%;
  height: auto;
  padding: 15px;
  margin-top: 15px;
  fill: #fff;
  background-color: #bf754b;
  object-fit: contain;
}
.competence .icone-svg path {
  fill: inherit;
}
.competence .conteneur-boutons {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0;
  background-color: #fff;
}
.competence .conteneur-boutons button, .competence .conteneur-boutons span {
  width: 33.3333333333%;
  margin: 0;
  padding: 10px;
  text-align: center;
  border: none;
  background-color: transparent;
}
.competence .conteneur-boutons button {
  cursor: pointer;
}