@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.texteSection #basPage .combat {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;
  color: #fff;
}
@media (max-width: 980px) {
  .texteSection #basPage .combat {
    flex-wrap: wrap;
  }
}
@media (max-width: 479px) {
  .texteSection #basPage .combat {
    gap: 10px;
  }
}
.texteSection #basPage .combat .des-conteneur {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.texteSection #basPage .combat .des-conteneur.conteneur-centre {
  width: 100%;
}
@media (max-width: 980px) {
  .texteSection #basPage .combat .des-conteneur {
    width: 100%;
  }
}
@media (max-width: 980px) {
  .texteSection #basPage .combat .des-conteneur > p {
    order: 1;
  }
}
.texteSection #basPage .combat .personnage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
@media (max-width: 980px) {
  .texteSection #basPage .combat .personnage {
    max-width: 300px;
    order: -1;
  }
}
@media (max-width: 479px) {
  .texteSection #basPage .combat .personnage {
    width: calc(50% - 5px);
  }
}
.texteSection #basPage .combat .dice {
  display: flex;
  justify-content: center;
}
.texteSection #basPage .combat img {
  width: 100%;
  max-width: 200px;
  height: 200px;
  object-fit: contain;
}
.texteSection #basPage .combat p {
  margin: 0;
  text-align: center;
}
.texteSection #basPage .combat p.nom, .texteSection #basPage .combat p .force-attaque {
  font-weight: bold;
}
.texteSection #basPage .combat p.nom {
  text-transform: capitalize;
}
.texteSection #basPage .combat p .force-attaque {
  display: inline-block;
  animation: 1.2s anim-popoutin ease-in-out;
}

@keyframes anim-popoutin {
  0%, 100% {
    transform: scale(1);
    text-shadow: none;
  }
  50% {
    transform: scale(2);
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }
}