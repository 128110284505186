.barreDeVie {
  position: relative;
  height: 20px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 50px;
  overflow: hidden;
}
.barreDeVie .filler {
  height: 100%;
  margin: 0 auto 0 0;
  border-radius: inherit;
  transition: width 0.5s ease-in-out;
}
.barreDeVie .labelContainer {
  position: absolute;
  top: 0;
  text-align: center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.barreDeVie .labelContainer .label {
  padding: 5px;
  color: black;
  font-weight: bold;
}