@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.bouton {
  position: relative;
  display: block;
  margin: 0 auto;
  width: fit-content;
  padding: 8px 16px;
  border-radius: 14px;
  background-color: #fff;
  border: 2px solid #fff;
  color: #bf754b;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  z-index: 0;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  opacity: 1;
}
@media (max-width: 767px) {
  .bouton {
    font-size: 12px;
    padding: 8px 12px;
  }
}
.bouton.disabled {
  opacity: 0.7;
  cursor: default;
}
.bouton span {
  position: absolute;
  width: 25%;
  height: 100%;
  background-color: #bf754b;
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
}
.bouton span:nth-child(1) {
  --n: 1;
}
.bouton span:nth-child(2) {
  --n: 2;
}
.bouton span:nth-child(3) {
  --n: 3;
}
.bouton span:nth-child(4) {
  --n: 4;
}
.bouton:hover {
  color: #fff;
}
.bouton:hover span {
  transform: translateY(0) scale(2);
}