@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.texteSection {
  display: flex;
  flex-direction: column;
  justify-content: end;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
}
.texteSection > .bouton {
  height: fit-content;
  position: fixed;
  right: 15px;
  border-color: #bf754b;
  top: 15px;
}
@media (max-width: 980px) {
  .texteSection > .bouton {
    position: absolute;
  }
}
.texteSection #basPage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}
.texteSection #basPage .texte {
  display: block;
  width: inherit;
  min-height: 220px;
  max-height: 400px;
  overflow-y: auto;
  padding: 3em;
  border-radius: 20px;
  color: #000;
  background-color: rgba(223, 223, 223, 0.7921568627);
  text-align: justify;
  font-size: 15px;
  scrollbar-color: #bf754b #fff;
  position: relative;
}
@media (max-width: 1200px) {
  .texteSection #basPage .texte {
    max-height: 320px;
  }
}
@media (max-width: 767px) {
  .texteSection #basPage .texte {
    font-size: 13px;
    padding: 3em 1.5em 1.5em 1.5em;
  }
}
@media (max-width: 479px) {
  .texteSection #basPage .texte {
    font-size: 12px;
  }
}
.texteSection #basPage .texte svg, .texteSection #basPage .texte path {
  position: absolute;
  width: 3em;
  height: 3em;
  object-fit: contain;
  right: 5px;
  top: 5px;
}
@media (max-width: 767px) {
  .texteSection #basPage .texte svg, .texteSection #basPage .texte path {
    width: 1.5em;
    height: 1.5em;
  }
}
.texteSection #basPage .action {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
  align-items: center;
}
@media (max-width: 479px) {
  .texteSection #basPage .action {
    flex-wrap: wrap;
  }
}
.texteSection #basPage .action .dice {
  display: flex;
  justify-content: center;
}
.texteSection #basPage .action h2, .texteSection #basPage .action input, .texteSection #basPage .action p {
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #000;
}
.texteSection #basPage .action input {
  border-width: 0;
  border-color: #fff;
}
.texteSection #basPage .action input::placeholder {
  color: rgba(0, 0, 0, 0.65);
}
.texteSection #basPage .finDeSection {
  flex-direction: column;
  text-align: center;
}
.texteSection #basPage article {
  width: auto;
}
.texteSection #basPage article button {
  width: 100%;
}
.texteSection #basPage article #somme {
  display: none;
}
.texteSection .formulaire {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.texteSection .formulaire #basPage input {
  width: 100%;
  text-align: center;
  background-color: transparent;
  border-bottom: 2px solid #cccccc;
  padding: 10px 5px;
  transition: 0.4s ease-in-out;
}
.texteSection .formulaire #basPage input::placeholder {
  transition: 0.4s ease-in-out;
}
.texteSection .formulaire #basPage input:focus {
  color: #bf754b;
  border-bottom-color: #bf754b;
}
.texteSection .formulaire #basPage input:focus ::placeholder {
  color: #bf754b;
}
.texteSection .formulaire #basPage .button {
  border: 0;
  transition: 0.5s;
  min-width: 15rem;
  padding: 1rem 2rem;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  background: #bf754b;
  color: #fff;
}
.texteSection .formulaire #basPage .button.--border {
  border: 2px solid transparent;
}
.texteSection .formulaire #basPage .button.--border:hover {
  border-color: #bf754b;
  color: #bf754b;
  background: transparent;
}