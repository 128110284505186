@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.accueil {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../medias/images/voiture.jpeg");
}
.accueil section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 20px 10px;
  min-height: 100vh;
}
.accueil section h1 {
  color: #fff;
}
.accueil section svg, .accueil section path {
  fill: white;
  width: 2em;
  height: 2em;
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
}