@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.popup-recommencer.popup .popup-conteneur {
  max-width: 400px;
  display: flex;
  justify-content: center;
}

.popup-recommencer.popup .popup-conteneur .conteneur-interne {
  width: 100%;
}

.popup-recommencer.popup .popup-conteneur .conteneur-interne .popup-contenu {
  display: flex;
  justify-content: center;
}

.popup-recommencer.popup .popup-conteneur .conteneur-interne .popup-titre p {
  margin-top: 2em;
}