@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.profil {
  padding-top: 80px;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../medias/images/personnage-adosse-voiture.jpeg") fixed center center/cover no-repeat, #000;
  color: white;
}
.profil .bouton-grand:first-child {
  position: fixed;
  right: 15px;
  top: 15px;
  border-color: #bf754b;
}
@media (max-width: 767px) {
  .profil .bouton-grand:first-child {
    position: absolute;
  }
}
.profil .contenuProfil {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 3em 2em;
  margin: 0 auto;
  max-width: 1600px;
}
@media (max-width: 1405px) {
  .profil .contenuProfil {
    flex-direction: column;
    align-items: center;
  }
}
.profil .contenuProfil .pasDePerso {
  margin: 1em 0 2em 0;
}
.profil .contenuProfil > div {
  border: solid 2px white;
  background-color: rgba(163, 163, 163, 0.344);
  border-radius: 10px;
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5em;
}
@media (max-width: 1405px) {
  .profil .contenuProfil > div {
    width: 80%;
  }
}
@media (max-width: 767px) {
  .profil .contenuProfil > div {
    width: 100%;
  }
}
.profil .contenuProfil > div > div {
  padding: 1em;
}
.profil .contenuProfil > div .competences-infos {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  padding: 1em;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .profil .contenuProfil > div .competences-infos {
    max-width: 260px;
    flex-wrap: wrap;
  }
}