@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  padding: 20px 20px 40px 20px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../medias/images/personnage-station-essence.jpeg");
}
@media (max-width: 767px) {
  .section {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 90px 20px 40px 20px;
  }
}
@media (max-width: 479px) {
  .section {
    gap: 20px 0;
  }
}
.section .competences-infos {
  display: flex;
  gap: 20px;
  height: min-content;
}
@media (max-width: 1200px) {
  .section .competences-infos {
    order: -2;
  }
}
@media (max-width: 767px) {
  .section .competences-infos {
    flex-wrap: wrap;
    max-width: 200px;
  }
}
@media (max-width: 479px) {
  .section .competences-infos {
    width: 50%;
    gap: 10px;
  }
}
@media (max-width: 767px) {
  .section .competences-infos .competence-info {
    width: calc(50% - 10px);
  }
}
@media (max-width: 479px) {
  .section .competences-infos .competence-info {
    width: calc(50% - 5px);
  }
}
.section .inventaire {
  position: absolute;
  bottom: 15px;
  left: 15px;
  width: 250px;
  height: fit-content;
}
@media (max-width: 1405px) {
  .section .inventaire {
    width: 170px;
  }
}
@media (max-width: 1200px) {
  .section .inventaire {
    position: relative;
    order: -1;
    width: 100px;
  }
}
@media (max-width: 479px) {
  .section .inventaire {
    text-align: right;
    width: 50%;
  }
}
.section .inventaire img {
  width: inherit;
  object-fit: contain;
  cursor: pointer;
}
@media (max-width: 479px) {
  .section .inventaire img {
    width: 100%;
    max-width: 120px;
  }
}