@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.ttf") format("truetype");
}
@font-face {
  font-family: "RussoOne";
  src: url("../fonts/russoOne.ttf") format("truetype");
}
.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  z-index: 1000;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #bf754b;
  transform-origin: top;
}

.inputModification {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 1.5em auto;
  gap: 20px;
  max-width: 700px;
}
@media (max-width: 767px) {
  .inputModification {
    flex-wrap: wrap;
    max-width: 450px;
  }
}
@media (max-width: 479px) {
  .inputModification {
    gap: 10px;
  }
}
.inputModification > * {
  width: 33.3333333333%;
}
@media (max-width: 767px) {
  .inputModification > * {
    width: calc(50% - 10px);
  }
}
@media (max-width: 479px) {
  .inputModification > * {
    width: 100%;
  }
}
.inputModification input {
  color: white;
  text-align: center;
}
.inputModification input:focus::placeholder {
  color: unset;
}
.inputModification p, .inputModification input {
  text-align: center;
}
.inputModification label {
  font-size: 1.2em;
}